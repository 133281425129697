
import {defineComponent} from 'vue';

import RuiHeader from '@/retinus-ui/components/layout/header/RuiHeader.vue';
import PvpModalConfirm from '@/app/components/modal/PvpModalConfirm.vue';

import {IMG_PROFILE, NAVIGATION_MENU} from '@/app/core/constants';
import {AuthModuleKey} from '@/app/data-flow/auth';
import {mapActions} from 'vuex';
import {MSQ_CONFIRM} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'pvp-header',
  components: {
    RuiHeader,
    PvpModalConfirm,
  },
  props: {
    authUser: Object,
  },
  data() {
    return {
      navigationMenu: NAVIGATION_MENU,
      userPhotoMin: IMG_PROFILE,
      msqConfirm: MSQ_CONFIRM.logout,
      showConfirmation: false,
    };
  },
  methods: {
    ...mapActions(AuthModuleKey, ['logout']),

    modalConfirm() {
      this.showConfirmation = false;
      if (this.authUser) {
        this.logout({user: this.authUser})
          .then(() => {
            this.$router.push({name: 'PvpAuthLoginPage'});
          });
      }
    },

    modalConfirmCancel() {
      this.showConfirmation = false;
    },

    logOut() {
      this.showConfirmation = true;
    },
  },
});
