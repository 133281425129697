import {CarListItem} from '@/app/core/interfaces';
import {CAMERA_TAG} from '@/app/core/constants';
import {COLOR_TYPE} from '@/retinus-ui/core/constants';

export default class CarListTools {
  public static sortByWeaponTag(list: CarListItem[]): CarListItem[] {
    if (!list) {
      return list;
    }

    return list.sort((a, b) => {
      if (!a.cameras.length) return 0;
      if (a.tags.includes(CAMERA_TAG.weapon)) {
        return -1;
      }

      return 0;
    });
  }

  public static isDanger(list: CarListItem[]): boolean {
    if (!list) {
      return false;
    }

    return list.some((car) => car.tags.some((tag) => tag.toLowerCase() === CAMERA_TAG.weapon));
  }

  public static isActive(itemId: string, comparableId: string): string {
    if (!itemId) return '';

    return comparableId === itemId ? 'is-active' : '';
  }

  public static checkTypeTag(tag: string): string {
    switch (tag.toLowerCase()) {
      case CAMERA_TAG.weapon:
        return COLOR_TYPE.danger;
      case CAMERA_TAG.warrant:
        return COLOR_TYPE.info;
      case CAMERA_TAG.trafficAccident:
        return COLOR_TYPE.warning;
      default:
        return '';
    }
  }
}
