<template>
  <rui-sidebar>
    <template v-slot:alert
              v-if="isDangerState">
      <rui-alert :text="alertText"
                 :type="alertType"/>
    </template>
    <template v-slot:navigation>
      <a v-for="(car, index) in carListState"
         :key="index"
         @click.prevent="onSelectCar(car.id)"
         class="rui-sidebar__item"
         :class="`pvp-item ${checkTypeNavItem(car)} ${isActive(car.id)}`">
        <div class="pvp-item__head">
          <div class="pvp-item__title">{{ car.carNumber }}</div>
          <div class="pvp-item__toolbar">
            <div class="pvp-item__alert">
              <template v-if="!car.cameras.length">
                <rui-icon class="pvp-ico"
                          icon="warning"/>
                <span>offline</span>
              </template>

              <template v-if="car.timer && car.cameras.length">
                <rui-icon class="pvp-ico"
                          icon="timer"/>
                <span>{{ car.timer }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="pvp-item__body">
          <rui-date :text="car.date"/>
          <rui-list-flat class="pvp-item__tags">
            <rui-badge v-for="(tag, index) in car.tags"
                       :key="index"
                       :text="tag"
                       :type="carTools.checkTypeTag(tag)"/>
          </rui-list-flat>
        </div>
      </a>
    </template>
  </rui-sidebar>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import RuiSidebar from '@/retinus-ui/components/layout/sidebar/RuiSidebar.vue';

import {mapGetters} from 'vuex';
import {CarsModuleKey, GetterTypesFromCars} from '@/app/data-flow/cars';

import {CarListItem} from '@/app/core/interfaces';
import {CAMERA_TAG, CAMERA_WEAPON_ALERT} from '@/app/core/constants';

import CarListTools from '@/app/core/CarListTools';

export default defineComponent({
  name: 'pvp-sidebar',
  emits: ['onSelectCar'],
  components: {
    RuiBadge,
    RuiAlert,
    RuiIcon,
    RuiDate,
    RuiListFlat,
    RuiSidebar,
  },
  computed: {
    ...mapGetters(CarsModuleKey, {
      carListState: GetterTypesFromCars.CAR_LIST_SORTED,
      isDangerState: GetterTypesFromCars.IS_DANGER,
      currentCarState: GetterTypesFromCars.CURRENT_CAR,
    }),
  },
  data() {
    return {
      alertText: CAMERA_WEAPON_ALERT.text as string,
      alertType: CAMERA_WEAPON_ALERT.type as string,
      carTools: CarListTools,
    };
  },
  methods: {
    checkTypeNavItem(car: CarListItem) {
      const danger = car.tags.find((tag) => tag === CAMERA_TAG.weapon);

      if (!car.cameras.length) {
        return 'is-offline';
      }

      if (danger) {
        return 'is-danger';
      }
      return '';
    },

    onSelectCar(carId: string): void {
      this.$router.push({
        name: 'PvpCarDetailPage',
        params: {id: carId},
      });
      this.$emit('onSelectCar');
    },

    isActive(carId: string): string {
      return CarListTools.isActive(carId, this.currentCarState?.id);
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'sidebar';
</style>
