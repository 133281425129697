
import {defineComponent} from 'vue';

import RuiModal from '@/retinus-ui/components/modal/RuiModal.vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';

import {DIRECTION} from '@/retinus-ui/core/constants';
import CarListTools from '@/app/core/CarListTools';

export default defineComponent({
  name: 'pvp-monitoring-modal',
  components: {
    RuiModal,
    RuiDate,
    RuiBadge,
    RuiListFlat,
  },
  props: {
    camera: {
      type: Object,
    },
    car: {
      type: Object,
    },
  },
  data() {
    return {
      carTools: CarListTools,
      right: DIRECTION.right,
    };
  },
});
