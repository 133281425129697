
import {defineComponent} from 'vue';
import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import RuiSidebar from '@/retinus-ui/components/layout/sidebar/RuiSidebar.vue';

import {mapGetters} from 'vuex';
import {CarsModuleKey, GetterTypesFromCars} from '@/app/data-flow/cars';

import {CarListItem} from '@/app/core/interfaces';
import {CAMERA_TAG, CAMERA_WEAPON_ALERT} from '@/app/core/constants';

import CarListTools from '@/app/core/CarListTools';

export default defineComponent({
  name: 'pvp-sidebar',
  emits: ['onSelectCar'],
  components: {
    RuiBadge,
    RuiAlert,
    RuiIcon,
    RuiDate,
    RuiListFlat,
    RuiSidebar,
  },
  computed: {
    ...mapGetters(CarsModuleKey, {
      carListState: GetterTypesFromCars.CAR_LIST_SORTED,
      isDangerState: GetterTypesFromCars.IS_DANGER,
      currentCarState: GetterTypesFromCars.CURRENT_CAR,
    }),
  },
  data() {
    return {
      alertText: CAMERA_WEAPON_ALERT.text as string,
      alertType: CAMERA_WEAPON_ALERT.type as string,
      carTools: CarListTools,
    };
  },
  methods: {
    checkTypeNavItem(car: CarListItem) {
      const danger = car.tags.find((tag) => tag === CAMERA_TAG.weapon);

      if (!car.cameras.length) {
        return 'is-offline';
      }

      if (danger) {
        return 'is-danger';
      }
      return '';
    },

    onSelectCar(carId: string): void {
      this.$router.push({
        name: 'PvpCarDetailPage',
        params: {id: carId},
      });
      this.$emit('onSelectCar');
    },

    isActive(carId: string): string {
      return CarListTools.isActive(carId, this.currentCarState?.id);
    },
  },
});
