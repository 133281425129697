<template>
  <span class="pvp-hint--invalid">{{text}}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'pvp-hint',
  props: {
    text: String,
  },
});
</script>

<style lang="scss" scoped>
@import 'hint';
</style>
