<template>
  <rui-modal :confirmation="true"
             :footerTextDirection="true"
             :title="name">
    <template v-slot:body>
      <p>{{ message }}</p>
    </template>
    <template v-slot:footer>
      <rui-list-flat>
        <rui-button :buttonText="confirmBtn.cancel"
                    @clickFromButtonComponent="$emit('cancel');"></rui-button>
        <rui-button :buttonText="confirmBtn.confirm"
                    :buttonType="buttonTypeConfirm"
                    @clickFromButtonComponent="$emit('confirm');"></rui-button>
      </rui-list-flat>
    </template>
  </rui-modal>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import RuiModal from '@/retinus-ui/components/modal/RuiModal.vue';

import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import {BUTTON_TYPE, MODAL_CONFIRM_BTN, MODAL_CONFIRM_TITLE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'pvp-modal-confirm',
  components: {
    RuiModal,
    RuiButton,
    RuiListFlat,
  },
  props: {
    name: {
      type: String,
      default: MODAL_CONFIRM_TITLE,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmBtn: MODAL_CONFIRM_BTN,
      buttonTypeConfirm: BUTTON_TYPE.primary,
    };
  },
});
</script>
