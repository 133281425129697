import {CameraListItem, CarListItem} from '@/app/core/interfaces';
import CarListTools from '@/app/core/CarListTools';
import CarListDataProvider from '@/app/data-flow/cars/CarListDataProvider';
import {ActionContext} from 'vuex';

const carListDataProvider = new CarListDataProvider();

export const CarsModuleKey = 'CarsModule';

export const GetterTypesFromCars = {
  CAR_LIST: 'CAR_LIST',
  CAR_LIST_SORTED: 'CAR_LIST_SORTED',
  CAR_LIST_LOADING_STATE: 'CAR_LIST_LOADING_STATE',
  CAR_LIST_ERROR_STATE: 'CAR_LIST_ERROR_STATE',
  IS_DANGER: 'IS_DANGER',
  CURRENT_CAR: 'CURRENT_CAR',
  CURRENT_CAMERA: 'CURRENT_CAMERA',
  MONITORING_MODAL_VISIBILITY: 'MONITORING_MODAL_VISIBILITY',
};

export interface State {
  carList?: CarListItem[];
  loading: boolean;
  error?: Error;
  currentCamera?: CameraListItem;
  monitoringModalVisibility: boolean;
}

const initialState = (): State => ({
  carList: undefined,
  loading: false,
  error: undefined,
  currentCamera: undefined,
  monitoringModalVisibility: false,
});

const getters = {
  [GetterTypesFromCars.CAR_LIST_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromCars.CAR_LIST_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromCars.CAR_LIST](state: State) {
    return state.carList;
  },
  [GetterTypesFromCars.CAR_LIST_SORTED](state: State) {
    return CarListTools.sortByWeaponTag([...(state.carList || [])]);
  },
  [GetterTypesFromCars.IS_DANGER](state: State) {
    return CarListTools.isDanger(state.carList || []);
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromCars.CURRENT_CAR](state: State, _getters, rootState) {
    return (state.carList || []).find((car) => car.id === rootState.RouteModule?.params?.id);
  },
  [GetterTypesFromCars.CURRENT_CAMERA](state: State) {
    return state.currentCamera;
  },
  [GetterTypesFromCars.MONITORING_MODAL_VISIBILITY](state: State) {
    return state.monitoringModalVisibility;
  },
};

const actions = {
  loadCarList(context: ActionContext<State, unknown>) {
    context.commit('loadCarList');

    carListDataProvider.getCarList()
      .then((response) => {
        const data = [...response];
        context.commit('loadCarListSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadCarListFail', {error});
      });
  },

  showMonitoringModalSuccess(context: ActionContext<State, unknown>, { camera }: { camera: CameraListItem }): void {
    context.commit('showMonitoringModalSuccess', { camera });
  },

  closeMonitoringModalSuccess(context: ActionContext<State, unknown>): void {
    context.commit('closeMonitoringModalSuccess');
  },
};

const mutations = {
  loadCarList(state: State) {
    state.loading = true;
    state.error = undefined;
    state.carList = undefined;
  },
  loadCarListSuccess(state: State, payload: {data: CarListItem[]}) {
    state.loading = false;
    state.error = undefined;
    state.carList = payload.data;
  },
  loadCarListFail(state: State, payload: {error: Error}) {
    state.loading = false;
    state.error = payload.error;
    state.carList = undefined;
  },

  showMonitoringModalSuccess(state: State, payload: {camera: CameraListItem}) {
    state.currentCamera = payload.camera;
    state.monitoringModalVisibility = true;
  },

  closeMonitoringModalSuccess(state: State) {
    state.currentCamera = undefined;
    state.monitoringModalVisibility = false;
  },
};

export const CarsModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
