import { createApp } from 'vue';
import { sync } from 'vuex-router-sync';
import router from './app/router';

import store from './app/data-flow';
import './styles/core/config.scss';
import App from './app/App.vue';
import './registerServiceWorker';

createApp(App)
  .use(router)
  .use(store)
  .mount('#app');

sync(store, router, { moduleName: 'RouteModule' });
