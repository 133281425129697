import {User} from '@/app/core/interfaces';
import {USER_KEY} from '@/app/core/constants';

export default class AuthDataProvider {
  // TODO: mock data.
  login(user: User): Promise<string> {
    // eslint-disable-next-line max-len
    const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEwIiwiZmlyc3ROYW1lIjoiVXNlciIsImxhc3ROYW1lIjoiTGFzdE5hbWUiLCJlbWFpbCI6InVzZXJAcmV0aW51cy5pbyIsInBob3RvIjoiaHR0cHM6Ly9zdXBwbGllcmRpdmVyc2l0eS51cG1jLmNvbS9Db250ZW50L2ltZy9pY29ucy9hdmF0YXIucG5nIiwiaWF0IjoxNjMxODUxNjY4fQ.U8l7Rxix1Z_lEh6_rxJ6sQwIzC5WtEnmKxvEsSc5yl4';
    return new Promise((resolve) => {
      if (user.email === 'user@retinus.io' && user.password === 'P@ssw0rd') {
        resolve(TOKEN);
      }
    });
  }

  async logout(user: User): Promise<User> {
    return {};
  }

  setupLocalStorageToken(user: string): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getLocalStorageToken(): string | null {
    return localStorage.getItem(USER_KEY);
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }
}
