
import {defineComponent} from 'vue';

import {CarsModuleKey, GetterTypesFromCars} from '@/app/data-flow/cars';
import {mapActions, mapGetters} from 'vuex';

import {CarListItem} from '@/app/core/interfaces';

import PvpSidebar from '@/app/components/layout/sidebar/PvpSidebar.vue';
import PvpBackButton from '@/app/components/back-button/PvpBackButton.vue';
import PvpMonitoringModal from '@/app/views/car-detail-page/components/monitoring-modal/PvpMonitoringModal.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';

import {BUTTON_TYPE} from '@/retinus-ui/core/constants';
import PvpHeader from '@/app/components/layout/header/PvpHeader.vue';
import {AuthModuleKey, GetterTypesFromAuth} from '@/app/data-flow/auth';

export default defineComponent({
  name: 'pvp-cars-page',
  components: {
    PvpSidebar,
    PvpBackButton,
    PvpMonitoringModal,
    RuiIcon,
    RuiButton,
    PvpHeader,
  },
  computed: {
    ...mapGetters(AuthModuleKey, {
      authorizedUserState: GetterTypesFromAuth.AUTHORIZED_USER,
    }),
    ...mapGetters(CarsModuleKey, {
      carListState: GetterTypesFromCars.CAR_LIST,
      currentCarState: GetterTypesFromCars.CURRENT_CAR,
      currentCameraState: GetterTypesFromCars.CURRENT_CAMERA,
      monitoringModalVisibilityState: GetterTypesFromCars.MONITORING_MODAL_VISIBILITY,
    }),
  },
  data() {
    return {
      isOpenMiddleContent: false as boolean,
      buttonTypeIcon: BUTTON_TYPE.icon as string,
    };
  },
  methods: {
    ...mapActions(CarsModuleKey, ['closeMonitoringModalSuccess']),

    watchCarListState(carList: CarListItem[]): void {
      if (carList) {
        const firstId = carList[0].id;
        this.$router.push({
          name: 'PvpCarDetailPage',
          params: {id: firstId},
        });
      }
    },

    onOpenMiddleContent(): void {
      this.isOpenMiddleContent = true;
    },

    onCloseMiddleContent(): void {
      this.isOpenMiddleContent = false;
    },

    closeModal(): void {
      this.closeMonitoringModalSuccess();
    },
  },
  beforeMount() {
    this.watchCarListState(this.carListState);
  },
});
