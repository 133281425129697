import { ActionContext } from 'vuex';

import AuthDataProvider from '@/app/data-flow/auth/AuthDataProvider';
import { User } from '@/app/core/interfaces';

const authDataProvider = new AuthDataProvider();
const initialUser = authDataProvider.getLocalStorageToken();

export const AuthModuleKey = 'AuthModule';

export const GetterTypesFromAuth = {
  AUTHORIZED_USER: 'AUTHORIZED_USER',
};

export interface State {
  loading: boolean;
  error?: Error;
  authorizedUser?: User;
}

const initialState = (): State => ({
  loading: false,
  error: undefined,
  authorizedUser: initialUser ? JSON.parse(window.atob(initialUser.split('.')[1])) : undefined,
});

const getters = {
  [GetterTypesFromAuth.AUTHORIZED_USER](state: State): User | undefined {
    return state.authorizedUser;
  },
};

const actions = {
  login(context: ActionContext<State, unknown>, { user }: {
    user: User;
  }): Promise<void> {
    context.commit('login');

    return authDataProvider.login(user)
      .then((response) => {
        authDataProvider.setupLocalStorageToken(response);

        context.commit('loginSuccess', { user: response });
      })
      .catch((error) => {
        authDataProvider.clearLocalStorage();
        context.commit('loginFail', { error });
      });
  },

  logout(context: ActionContext<State, unknown>, {user}: {
    user: User;
  }): Promise<void> {
    context.commit('logout');

    return authDataProvider.logout(user)
      .then(() => {
        authDataProvider.clearLocalStorage();
        context.commit('logoutSuccess', {user});
      }).catch((error) => {
        context.commit('logoutFail', {error});
      });
  },
};

const mutations = {
  login(state: State): void {
    state.loading = true;
    state.error = undefined;
  },

  loginSuccess(state: State, payload: { user: string }): void {
    state.loading = false;
    state.error = undefined;
    state.authorizedUser = JSON.parse(window.atob(payload.user.split('.')[1]));
  },

  loginFail(state: State, payload: { error: Error }): void {
    state.loading = false;
    state.error = payload.error;
    console.error('loginFail', payload);
  },

  logout(state: State) {
    state.loading = true;
    state.error = undefined;
  },

  logoutSuccess(state: State, payload: { user: User }) {
    state.loading = false;
    state.error = undefined;
    state.authorizedUser = payload.user;
  },

  logoutFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
  },
};

export const AuthModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
