import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import PvpCarsPage from '@/app/views/cars-page/PvpCarsPage.vue';
import PvpCarDetailPage from '@/app/views/car-detail-page/PvpCarDetailPage.vue';
import PvpAuthLoginPage from '@/app/views/auth-page/PvpAuthLoginPage.vue';

import AuthDataProvider from '@/app/data-flow/auth/AuthDataProvider';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'cars',
    meta: {requiresAuth: true},
  },
  {
    path: '/login',
    name: 'PvpAuthLoginPage',
    component: PvpAuthLoginPage,
  },
  {
    path: '/cars',
    name: 'PvpCarPage',
    component: PvpCarsPage,
    meta: {requiresAuth: true},
    children: [
      {
        path: ':id',
        name: 'PvpCarDetailPage',
        component: PvpCarDetailPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authDataProvider = new AuthDataProvider();
  const token = authDataProvider.getLocalStorageToken();

  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next({
      name: 'PvpAuthLoginPage',
      params: { nextUrl: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
