<template>
  <rui-modal :title="`${camera.direction}:&nbsp;${car.carNumber}`">
    <template v-slot:head>
      <ul class="pvp-modal__toolbar">
        <li>
          <rui-list-flat :direction="right">
            <rui-badge v-for="(tag, index) in car.tags"
                       :key="index"
                       :text="tag"
                       :type="carTools.checkTypeTag(tag)"/>
          </rui-list-flat>
        </li>
        <li>
          <rui-date :text="car.date"/>
        </li>
      </ul>
    </template>
    <template v-slot:close>
      <slot name="close"/>
    </template>
    <template v-slot:img>
      <video loop autoplay muted
             class="pvp-card__video">
        <source :src="camera.videoUrl"
                type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </template>
  </rui-modal>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

import RuiModal from '@/retinus-ui/components/modal/RuiModal.vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';

import {DIRECTION} from '@/retinus-ui/core/constants';
import CarListTools from '@/app/core/CarListTools';

export default defineComponent({
  name: 'pvp-monitoring-modal',
  components: {
    RuiModal,
    RuiDate,
    RuiBadge,
    RuiListFlat,
  },
  props: {
    camera: {
      type: Object,
    },
    car: {
      type: Object,
    },
  },
  data() {
    return {
      carTools: CarListTools,
      right: DIRECTION.right,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'monitoring-modal';
</style>
