<template>
  <rui-page-layout>
    <template v-slot:title>
      <div class="pvp-header">
        <p class="pvp-header__title">Car:&nbsp;{{ currentCarState.carNumber }}</p>
        <rui-date :text="currentCarState.date"/>
      </div>
    </template>
    <template v-slot:toolbar>
      <rui-list-flat>
        <rui-badge v-for="(tag, index) in currentCarState.tags"
                   :key="index"
                   :text="tag"
                   :type="carTools.checkTypeTag(tag)"/>
      </rui-list-flat>
    </template>
    <template v-slot:body>
      <rui-no-data :text="noDataText"
                   :data="currentCarState.cameras.length">
        <template v-slot:data>
          <div class="pvp-grid">
            <div class="pvp-grid__col"
                 v-for="(camera, index) in currentCarState.cameras"
                 :key="index">
              <rui-card-border class="pvp-card">
                <template v-slot:image>
                  <video loop autoplay muted
                         :key="camera.videoUrl"
                         class="pvp-card__video">
                    <source :src="camera.videoUrl"
                            type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </template>
                <template v-slot:footer>
                  <span class="pvp-direction">{{ camera.direction }}</span>
                  <rui-button :buttonType="buttonTypeIcon"
                              @clickFromButtonComponent="showModal(camera)">
                    <template v-slot:icon>
                      <rui-icon icon="open-in-new"/>
                    </template>
                  </rui-button>
                </template>
              </rui-card-border>
            </div>
          </div>
        </template>
      </rui-no-data>
    </template>
  </rui-page-layout>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiPageLayout from '@/retinus-ui/components/layout/pageLayout/RuiPageLayout.vue';
import RuiNoData from '@/retinus-ui/components/no-data/RuiNoData.vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiCardBorder from '@/retinus-ui/components/card-border/RuiCardBorder.vue';

import {mapActions, mapGetters} from 'vuex';
import {CarsModuleKey} from '@/app/data-flow/cars';
import {GetterTypes} from '@/app/data-flow/types';

import {CAMERAS_NO_DATA} from '@/app/core/constants';
import {CameraListItem} from '@/app/core/interfaces';
import CarListTools from '@/app/core/CarListTools';
import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'pvp-car-detail-page',
  components: {
    RuiBadge,
    RuiIcon,
    RuiButton,
    RuiPageLayout,
    RuiDate,
    RuiNoData,
    RuiListFlat,
    RuiCardBorder,
  },
  data() {
    return {
      carTools: CarListTools as unknown,
      noDataText: CAMERAS_NO_DATA as string,
      buttonTypeIcon: BUTTON_TYPE.icon as string,
    };
  },
  computed: {
    ...mapGetters(CarsModuleKey, {
      currentCarState: GetterTypes.CURRENT_CAR,
    }),
  },
  methods: {
    ...mapActions(CarsModuleKey, ['showMonitoringModalSuccess']),

    showModal(camera: CameraListItem) {
      if (camera) {
        this.showMonitoringModalSuccess({camera});
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'car-detail';
</style>
