<template>
  <rui-auth-wrapper :title="app.name">
    <template v-slot:body>
      <div class="pvp-auth-form__control">
        <rui-input type="email"
                   required
                   :modelValue="fieldValue.email"
                   :invalid="fieldValue.emailInvalid"
                   @changeFromInputComponent="changeEmail($event)"
                   @keyup.enter="submitForm()"
                   :placeholder="placeholder.enterEmail"/>
        <pvp-hint v-if="fieldValue.emailInvalid"
                  :text="hint.email"/>
      </div>
      <div class="pvp-auth-form__control">
        <rui-input :modelValue="fieldValue.password"
                   :invalid="fieldValue.passwordInvalid"
                   required
                   :type="passwordVisibility"
                   @changeFromInputComponent="changePassword($event)"
                   @keyup.enter="submitForm()"
                   :placeholder="placeholder.enterPassword">
          <template v-slot:buttons>
            <rui-button :buttonType="buttonType.icon"
                        type="button"
                        @click="switchVisibility()">
              <template v-slot:icon>
                <rui-icon :icon="isVisiblePassword()"/>
              </template>
            </rui-button>
          </template>
        </rui-input>
        <pvp-hint v-if="fieldValue.passwordInvalid"
                  :text="hint.password"/>
      </div>
      <rui-button class="rui-auth__btn"
                  :buttonText="buttonText.signIn"
                  :disabled="disableSubmitButton()"
                  @clickFromButtonComponent="submitForm()"
                  :button-type="buttonType.primary"
                  type="submit"/>
    </template>
  </rui-auth-wrapper>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {mapActions} from 'vuex';
import {AuthModuleKey} from '@/app/data-flow/auth';

import Validator from '@/retinus-ui/core/validator';
import PvpHint from '@/app/components/hint/PvpHint.vue';
import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiAuthWrapper from '@/retinus-ui/components/auth/RuiAuthWrapper.vue';

import {APP_INFO, BUTTON_TEXT, PLACEHOLDER_TEXT} from '@/app/core/constants';
import {BUTTON_TYPE, VALIDATE_HINT} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'pvp-auth-login-page',
  components: {
    RuiAuthWrapper,
    RuiInput,
    RuiIcon,
    RuiButton,
    PvpHint,
  },
  data() {
    return {
      app: APP_INFO,
      placeholder: PLACEHOLDER_TEXT,
      hint: VALIDATE_HINT,
      buttonType: BUTTON_TYPE,
      buttonText: BUTTON_TEXT,
      fieldValue: {
        email: '' as string,
        emailPrevious: '' as string,
        emailInvalid: false as boolean,
        password: '' as string,
        passwordPrevious: '' as string,
        passwordInvalid: false as boolean,
      },
      passwordVisibility: 'password' as string,
      formComplete: false as boolean,
    };
  },
  methods: {
    ...mapActions(AuthModuleKey, ['login']),

    changeEmail(value: string): void {
      this.fieldValue.emailInvalid = false;
      this.fieldValue.email = value;
    },

    changePassword(value: string): void {
      if (!value) {
        this.passwordVisibility = 'password';
      }

      this.fieldValue.passwordInvalid = false;
      this.fieldValue.password = value;
    },

    submitForm(): void {
      this.fieldValue.emailPrevious = this.fieldValue.email;
      this.fieldValue.passwordPrevious = this.fieldValue.password;

      if (!Validator.passwordValid(this.fieldValue.password)) {
        this.fieldValue.passwordInvalid = true;
      }
      if (!Validator.emailValid(this.fieldValue.email)) {
        this.fieldValue.emailInvalid = true;
      }

      if (Validator.passwordValid(this.fieldValue.password) && Validator.emailValid(this.fieldValue.email)) {
        const user = {
          email: this.fieldValue.email,
          password: this.fieldValue.password,
        };
        this.login({user})
          .then(() => {
            this.$router.push({name: 'PvpCarPage'});
          });
      } else {
        this.formComplete = true;
        this.fieldValue.emailInvalid = true;
        this.fieldValue.passwordInvalid = true;
      }
    },

    disableSubmitButton(): boolean {
      const anyFieldEmpty = !this.fieldValue.email || !this.fieldValue.password;
      const formPristine = !this.passwordChanged() && !this.emailChanged();

      return (anyFieldEmpty || this.formComplete) && (anyFieldEmpty || formPristine);
    },

    emailChanged(): boolean {
      return !this.fieldValue.emailPrevious && !this.fieldValue.email;
    },

    passwordChanged() {
      return this.fieldValue.passwordPrevious !== this.fieldValue.password;
    },

    isVisiblePassword(): string {
      return this.passwordVisibility === 'password' ? 'eyeClosed' : 'eye';
    },

    switchVisibility() {
      this.passwordVisibility = this.passwordVisibility === 'password' ? 'text' : 'password';
    },
  },
});
</script>

<style lang="scss">
@import 'auth-form';
</style>
