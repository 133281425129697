import { createLogger, createStore } from 'vuex';
import { CarsModuleKey, CarsModule } from '@/app/data-flow/cars';
import {AuthModule, AuthModuleKey} from '@/app/data-flow/auth';
import { GetterTypes } from './types';

const debug = process.env.NODE_ENV !== 'production';

const getters = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypes.GET_PATH](state, allGetters, rootState) {
    return rootState.RouteModule?.path;
  },
};

export default createStore({
  getters,
  modules: {
    [CarsModuleKey]: CarsModule,
    [AuthModuleKey]: AuthModule,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
