
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-data',
  props: {
    text: {
      type: String,
    },
    data: {
      type: Number,
    },
  },
});
