
import {defineComponent} from 'vue';

import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiPageLayout from '@/retinus-ui/components/layout/pageLayout/RuiPageLayout.vue';
import RuiNoData from '@/retinus-ui/components/no-data/RuiNoData.vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiCardBorder from '@/retinus-ui/components/card-border/RuiCardBorder.vue';

import {mapActions, mapGetters} from 'vuex';
import {CarsModuleKey} from '@/app/data-flow/cars';
import {GetterTypes} from '@/app/data-flow/types';

import {CAMERAS_NO_DATA} from '@/app/core/constants';
import {CameraListItem} from '@/app/core/interfaces';
import CarListTools from '@/app/core/CarListTools';
import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'pvp-car-detail-page',
  components: {
    RuiBadge,
    RuiIcon,
    RuiButton,
    RuiPageLayout,
    RuiDate,
    RuiNoData,
    RuiListFlat,
    RuiCardBorder,
  },
  data() {
    return {
      carTools: CarListTools as unknown,
      noDataText: CAMERAS_NO_DATA as string,
      buttonTypeIcon: BUTTON_TYPE.icon as string,
    };
  },
  computed: {
    ...mapGetters(CarsModuleKey, {
      currentCarState: GetterTypes.CURRENT_CAR,
    }),
  },
  methods: {
    ...mapActions(CarsModuleKey, ['showMonitoringModalSuccess']),

    showModal(camera: CameraListItem) {
      if (camera) {
        this.showMonitoringModalSuccess({camera});
      }
    },
  },
});
