import * as imgProfileMin from '@/assets/img/mockPhotoMin.jpg';

export const IMG_PROFILE = imgProfileMin;

export const NAVIGATION_MENU = [
  {
    name: 'dashboard',
    link: '/',
    icon: 'dashboard',
  },
  {
    name: 'settings',
    link: '/settings',
    icon: 'settings',
  },
  {
    name: 'support',
    link: '/support',
    icon: 'support',
  },
];

export const CAMERA_TAG = {
  weapon: 'weapon',
  altercation: 'altercation',
  trafficAccident: 'traffic accident',
  warrant: 'warrant',
};

export const CAMERA_WEAPON_ALERT = {
  text: 'alerts detected',
  type: 'warning',
};

export const CAMERAS_NO_DATA = 'No cameras...';

export const USER_KEY = 'pvp_token';

export const APP_INFO = {
  name: 'PVP Login',
  poweredBy: {
    name: 'powered by Retinus',
    link: 'https://www.retinus.io',
  },
};

export const PLACEHOLDER_TEXT = {
  enterEmail: 'Please enter your email',
  enterPassword: 'Please enter your password',
};

export const BUTTON_TEXT = {
  signIn: 'Sign in',
  confirm: 'Ok',
  cancel: 'cancel',
};
