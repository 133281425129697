<template>
  <slot v-if="data"
        name="data"/>
  <div v-if="!data"
       class="rui-no-data">
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-data',
  props: {
    text: {
      type: String,
    },
    data: {
      type: Number,
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'no-data';
</style>
