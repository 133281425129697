<template>
  <router-view/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {mapActions, mapGetters} from 'vuex';
import {CarsModuleKey} from '@/app/data-flow/cars';
import {AuthModuleKey, GetterTypesFromAuth} from '@/app/data-flow/auth';
import {User} from '@/app/core/interfaces';

export default defineComponent({
  data() {
    return {
      loggedIn: {} as User,
    };
  },
  computed: {
    ...mapGetters(AuthModuleKey, {
      authorizedUserState: GetterTypesFromAuth.AUTHORIZED_USER,
    }),
  },
  watch: {
    authorizedUserState: 'watchLoggedInState',
  },
  beforeMount() {
    this.watchLoggedInState(this.authorizedUserState);
  },
  methods: {
    ...mapActions(CarsModuleKey, ['loadCarList']),

    watchLoggedInState(val: User): void {
      this.loggedIn = val;
      if (this.loggedIn?.id) {
        this.loadCarList();
      }
    },
  },
});
</script>
