import {CarListItem} from '@/app/core/interfaces';
import {CAR_LIST_DATA} from '@/app/core/mockData';

export default class CarListDataProvider {
  getCarList(): Promise<CarListItem[]> {
    return new Promise((resolve) => {
      resolve(CAR_LIST_DATA);
    });
  }
}
