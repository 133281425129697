import * as videoUrl1 from '@/assets/video/retinus-pvp-demo-video1.mp4';
import * as videoUrl2 from '@/assets/video/retinus-pvp-demo-video2.mp4';
import * as videoUrl3 from '@/assets/video/retinus-pvp-demo-video3.mp4';
import * as videoUrl4 from '@/assets/video/retinus-pvp-demo-video4.mp4';
import * as videoUrl5 from '@/assets/video/retinus-pvp-demo-video5.mp4';
import * as videoUrl6 from '@/assets/video/retinus-pvp-demo-video6.mp4';
import * as videoUrl7 from '@/assets/video/retinus-pvp-demo-video7.mp4';
import * as videoUrl8 from '@/assets/video/retinus-pvp-demo-video8.mp4';
import * as videoUrl9 from '@/assets/video/retinus-pvp-demo-video9.mp4';
import * as videoUrl10 from '@/assets/video/retinus-pvp-demo-video10.mp4';

export const USER = {
  name: 'Saitama',
  role: 'hero',
};

const checkDate = (dateString: string | number | Date) => new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'long',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  day: '2-digit',
}).format(new Date(dateString));

export const CAR_LIST_DATA = [
  {
    id: '1weferwegsrheshjgsrv4ve',
    date: checkDate('2012-04-23T18:25:43.511Z'),
    timer: '0:32:23',
    carNumber: '24th street station',
    tags: ['altercation', 'weapon'],
    cameras: [
      {
        direction: 'front',
        id: '14aewfaf45656b7ub76u56',
        videoUrl: videoUrl1,
      },
      {
        direction: 'right',
        id: '14esrec6r7ur7vugg456',
        videoUrl: videoUrl2,
      },
      {
        direction: 'left',
        id: '144r5uc76vu76b7crer56',
        videoUrl: videoUrl3,
      },
      {
        direction: 'back',
        id: '1445dfrv67u67vu67ugdf6',
        videoUrl: videoUrl4,
      },
    ],
  },
  {
    id: '1wefergergsrggsrv4ve',
    date: checkDate('2012-04-23T18:25:43.511Z'),
    timer: '',
    carNumber: '28th street station',
    tags: ['warrant'],
    cameras: [
      {
        direction: 'front',
        id: '14aewfafffexrgrthyvy66666456',
        videoUrl: videoUrl5,
      },
      {
        direction: 'right',
        id: '14esreggc6y456',
        videoUrl: videoUrl6,
      },
      {
        direction: 'left',
        id: '14ax4t4y5yuh4crer56',
        videoUrl: videoUrl7,
      },
      {
        direction: 'back',
        id: '1445d56756767667fgdf6',
        videoUrl: videoUrl8,
      },
    ],
  },
  {
    id: '1wefffgrgregergasergsrv4ve',
    date: checkDate('2012-04-23T18:25:43.511Z'),
    timer: '',
    carNumber: '27th street station',
    tags: ['traffic accident'],
    cameras: [
      {
        direction: 'front',
        id: '14aewfaf456',
        videoUrl: videoUrl9,
      },
      {
        direction: 'right',
        id: '14esregg456',
        videoUrl: videoUrl10,
      },
      {
        direction: 'left',
        id: '144crer56',
        videoUrl: videoUrl1,
      },
      {
        direction: 'back',
        id: '1445dfgdf6',
        videoUrl: videoUrl2,
      },
    ],
  },
  {
    id: '3454wfs',
    date: checkDate('2012-04-23T18:25:43.511Z'),
    timer: '0:32:23',
    carNumber: '26th street station',
    tags: ['Altercation'],
    cameras: [
      {
        direction: 'front',
        id: '14aewfaffese456',
        videoUrl: videoUrl10,
      },
      {
        direction: 'right',
        id: '14esrwefwefegg456',
        videoUrl: videoUrl9,
      },
      {
        direction: 'left',
        id: '144creddeeer56',
        videoUrl: videoUrl2,
      },
      {
        direction: 'back',
        id: '1445dqwddewqefgdf6',
        videoUrl: videoUrl8,
      },
    ],
  },
  {
    id: '1wefergsrv4ve',
    date: checkDate('2012-04-23T18:25:43.511Z'),
    timer: '0:32:23',
    carNumber: '25th street station',
    tags: ['weapon'],
    cameras: [
      {
        direction: 'front',
        id: '14aewfaf4556456564ev6',
        videoUrl: videoUrl10,
      },
      {
        direction: 'right',
        id: '14esrvcc45egg456',
        videoUrl: videoUrl3,
      },
      {
        direction: 'left',
        id: '144cr45cywxcv6uer56',
        videoUrl: videoUrl4,
      },
      {
        direction: 'back',
        id: '1445ec5f6y56y6bue7u7bdfgdf6',
        videoUrl: videoUrl2,
      },
    ],
  },
];
